.rightbarFriend{
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    height: 47px;
  }
  .rightbarFriend:hover{
    background-color: gainsboro;
  }
  .rightbarProfileImageCont{
    position: relative;
    width: 45px;
  }
  
  .rightbarProfileImage{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
  .rightbarOnline{
    border:2px solid white;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: limegreen;
    position: absolute;
    top: 25px;
    right: 10px;
  }
  .rightbarUsername{
    opacity: 0.8;
    font-size: 14px;
    cursor: pointer;
  }