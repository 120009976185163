.share{
    width: 100%;
    position: relative;
}
.shareWrapper{
    width: 90%;
    -webkit-box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.75);
    border-radius: 10px;
    padding: 15px;
    background-color: white;
}

.shareTop{
    display: flex;
    
}
.shareProfileImage{
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 15px;
    cursor: pointer;
}


.shareInput{
    margin-left: 15px;
    width: 80%;
    font-size: 18px;
    border: none;
    background-color: rgb(240, 231, 231);
    border-radius: 20px;
    opacity: 0.6;

}
.shareInput:hover{
    cursor: pointer;
    outline: none;
    opacity: 0.8;
}

.shareHr{
    margin: 20px 0px;
    opacity: 0.3;
}

.shareOptions{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.shareOption{
    height: 40px;
    width:150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.shareOption:hover{
    border-radius: 5px;
    background-color: rgb(230, 230, 230);
}
.shareOptionText{
    margin-left:5px;
    font-weight: bold;
    font-size: 15px;
    color: rgb(146, 141, 141);
}
.shareIcon{
   font-size: 26px;
}


@media only screen and (max-width: 900px){
    .shareOptionText{
     display: flex;
    }
    .shareOptionLongText{
    display: none;
    }
   }

   @media only screen and (min-width: 900px){
    .shareOptionLongText{
     display: flex;
    }
    .shareOptionText{
    display: none;
    }
   }