.jobs_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.jobs{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.more {
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}