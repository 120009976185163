
.viewport {
    flex-grow: 1;
    box-sizing:border-box;
    max-width: inherit;
    /* padding: 5% 10% 5% 10%; */
    /* height: 200%; */
    color: #393333;
}
.homeContainer{
    display: flex;
    width: 100%;
    padding-top: 4.5% ;
}
.fullVisualHome{
    margin: 0;
    padding: 0;
    position: relative;
    background-color: whitesmoke;
}
.halfVisualHome{
    position: relative;
    opacity: 0.3;
}


