.network-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5% 10% 5% 10%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    align-items: center;
    flex-wrap: wrap;
}