.sidebarFriend {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 5px;
    height: 45px;
    width: 100%;
  }
  .sidebarFriend:hover{
    background-color: gainsboro;
  }
  
  .sidebarFriendImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    cursor: pointer;
  }

  .sidebarFriendName{
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
  }