.navbar {
    display: flex;
    padding-top: 9px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    /* height: 16%; */
    /* border-bottom: 1px solid gainsboro; */
    
}
.navbar-logo{
    height: 100%;
}

.navbar-tabs{
    height : 90px;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.tab{
    width: 100px;
    height: 80px;
    border-radius: 18px;
    margin-right: 8px;
}

.tab:hover {
    /* background-color: rgb(165, 165, 172); */
    color: white !important;
  }

.navTab{
    display: flex;
    height: 100%;
    width: 100%;
    scale: 0.7;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.iconText{
    font-size: 18px;
    margin-top: 10px;
}
.common{
    color: #393333;
}
.common:hover{
    color: grey;
}
.pending{
    color: rgb(218, 121, 121);
}
.active {
    /* color: #0a85d1; */
    color: grey;
}
.tabButton{
    cursor: pointer;
}
.tabButton :hover{
  color: red;
}

.active .dot{
    display: block;
}
.pending .dot{
    display: none;
}
.common .dot{
    display: none;
}