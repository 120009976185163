.jobs_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
   
}
.header{
    width: 100%;
    height: 10%;
    /* background-color: rgb(110, 49, 49); */
    border-radius: 1rem; 
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 30px;
}
.main{
    width: 100%;
    height: 90%;
    padding: 10px;
    /* background-color: black; */
    border-radius: 1rem; 
    display: flex;
    flex-direction: row;
}
.main_div{
    width: 100%;
    height: 100%;
    /* background-color: rgb(238, 132, 132); */
    border-radius: 1rem;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.actions_div{
    width: 100%;
    height: 100%;
    /* background-color: rgb(238, 132, 132); */
    border-radius: 1rem;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.actions_basket{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    /* background-color: rgb(238, 132, 132); */
    border-radius: 1rem;
    overflow-y: scroll;
}
.input{
    width: 100%;
    height: 50px;
    /* background-color: rgb(15, 14, 14); */
    border-radius: 1rem;
    /* border: 1px solid rgb(10, 41, 41); */
    margin-top: 10px;
    text-indent: 10px;
}

.action{
    width: 100%;
    height: 50px;
    background-color: rgb(10, 41, 41);
    border-radius: 1rem;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.action_button{
    width: 100%;
    height: 50px;
    background-color: rgb(10, 41, 41);
    color: white;
    border-radius: 1rem;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.action_div_header{
    width: 100%;
    height: 20%;
}
.action_div_left{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    color: white;
    width: 15%;
    height: 100%;

}
.action_div_center{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 100%;

}
.action_div_right{
    display: flex;
    color: white;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 1rem;
    width: 20%;
    height: 100%;

}
.jd{
    width: 100%;
    height: 100%;
    /* background-color: rgb(15, 14, 14); */
    border-radius: 1rem;
    padding: 10px;
    text-indent: 10px;
    margin-top: 10px;
}
label{
    padding: 10px;
}