.home{
    width: 100%;
    height: 100%;
    padding: 2% 10% 2% 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.stats{
    width: 100%;
    height: 60%;
    /* background-color: #f2f2f2; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.link_container{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    height: 40%;
    width: 80%;
}
.quick-links{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}