.homeContainer{
    display: flex;
    width: 100%;
}
.fullVisualHome{
    margin: 0;
    padding: 0;
    position: relative;
    background-color: whitesmoke;
}
.halfVisualHome{
    position: relative;
    opacity: 0.3;
}


