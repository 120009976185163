.knavbar {
  padding-inline: 2rem;
  border-bottom: 1px solid #ccc;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
}
.checkbox {
  opacity: 0;
  position: absolute;
}
.dark {
  background-color: #292c35;
}
.label {
  width: 36px;
  height: 11px !important;
  background-color: #111;
  display: flex;
  border-radius: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.5);
}

.ball {
  width: 16px;
  height: 16px;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked + .label .ball {
  transform: translateX(24px);
}

.fa-moon {
  color: pink;
}

.fa-sun {
  color: yellow;
}
