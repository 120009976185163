.pane {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.top-half{
    padding: 10px;
    box-sizing: border-box;
    border-radius: 20px;
    width: 100%;
    height: 40%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.bottom-half{
    border-radius: 20px;
    width: 100%;
    height: 60%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-top: 5%; */
    font-size: 100px;
    font-weight: 600;
}
.left{
    font-size: 1.6em;
    font-weight: 700;
    width: 120%;
    height: 100%;    
}
.right{
    width: 80%;
    height: 100%;  
    box-sizing: border-box;
    padding-right: 5%;
background-color: rgb(255, 255, 255);
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
}