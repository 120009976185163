body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .Kanban {
    scale: 0.9;
    height: 98vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    transition: all 350ms;
  }
  .app_outer {
    flex: 1;
    width: 100%;
    overflow-x: auto;
  }
  
  .app_boards {
    margin-top: 20px;
    width: 100%;
    /* min-width: fit-content; */
    display: flex;
    gap: 10px;
    padding-inline: 1rem;
    color: var(--text-color);
  }
  /* p {
    font-size: 16px;
    line-height: 28px;
  } */
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f2f2f2;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 20px 20px 20px 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(195, 195, 195);
  }
  .add__board > textarea {
    border: 0.5px solid rgb(167, 167, 167) !important;
    height: 2rem !important;
  }
  .card_detail > textarea {
    border: 0.5px solid rgb(167, 167, 167) !important;
  }
  .card__values > p {
    background: #ccc !important;
  }
  .cardinfo__editable {
    width: fit-content;
  }
  