body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gridContainer{
  display: grid;
  margin: 50px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)); /* Adjust the minimum and maximum width as needed */
  gap: 16px;
}

.marquee {
  width: 400px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  overflow: hidden;
  position: relative; /* Optional, for visual reference */
}

.content {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marqueeY 70s linear infinite;
}

@keyframes marqueeY {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%); /* Adjust this value based on content size */
  }
}
