.candidates_container{
    padding-top: 30px;
    width: '100%';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.candidates{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.candidate_card{
    margin-bottom: 20px;
    width: 30%;
}
.bottom_actions{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.shortlist:hover{
    background-color: white !important;
}