.home_action {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.action_container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.action_title{
    display: flex;
    padding-top: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
